import dynamic from "next/dynamic";
import { type JSX } from "react";
import { type Site } from "@/types/Sites";

const iconMapping = {
  autos: null,
  health: null,
  local: null,
  shopping: dynamic(() => import("@/components/notFound/ShoppingIcon")),
  tech: null,
};

export const DynamicIcons = ({
  className,
  site,
  iconName,
}: {
  className: string;
  site: Site;
  iconName: string;
}): JSX.Element => {
  const SiteIcon = iconMapping[site];

  if (!SiteIcon) {
    return <span />;
  }

  return <SiteIcon className={className} iconName={iconName} />;
};
