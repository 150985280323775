"use client";

import { useDeviceSize } from "@yahoo-commerce/util";
import { useMemo } from "react";
import { useBenjiConfig } from "@/components/monetization/useBenjiConfig";
import { getBenjiConfigForNotFound } from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";

export const NotFoundBenjiConfig = () => {
  const deviceSize = useDeviceSize({ updateOnResize: true });
  const requestContext = useRequestContext();

  const notFoundConfig = useMemo(() => {
    if (deviceSize) {
      return getBenjiConfigForNotFound({
        deviceSize,
        requestContext,
      });
    }
  }, [deviceSize, requestContext]);

  useBenjiConfig({
    benjiConfig: notFoundConfig,
    clearKeys: true,
    deviceSize,
    enableAdStacking: true,
    refreshLimit: 100,
  });

  return null;
};
