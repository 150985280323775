"use client";

import { Button, Text } from "@yahoo/uds";
import { getDataYlkString, I13nAnchor, useRapid } from "@yahoo-commerce/i13n";
import { DynamicIcons } from "@/components/notFound/DynamicIcon";
import { type CategoryLink } from "@/configs/notFound";
import { type Site } from "@/types/Sites";

interface NotFoundContentProps {
  categoryLinks: CategoryLink[];
  site: Site;
  subHeading: string;
  title: string;
  url: string;
  urlText: string;
}

export function NotFoundContent({
  categoryLinks,
  site,
  subHeading,
  title,
  url,
  urlText,
}: NotFoundContentProps) {
  const mainSec = "404-main-cta";
  const pillsSec = "404-pills-cta";
  const { mpos: mainPos } = useRapid(mainSec);
  const { mpos: pillsPos } = useRapid(pillsSec);
  const mainDataYlk = {
    cpos: 1,
    elm: "link",
    i13nSec: mainSec,
    itc: 0,
    mpos: mainPos,
    sec: mainSec,
  };

  return (
    <div className="flex flex-col items-center justify-center text-center lg:order-1 lg:max-w-[488px] lg:items-start lg:text-left">
      {title && (
        <Text
          variant="display1"
          color="primary"
          as="h2"
          className="mb-4 !font-centra !text-[40px] !normal-case !leading-[44px] !text-inkwell lg:mb-5 lg:!text-[48px] lg:!leading-[56px]"
        >
          {title}
        </Text>
      )}
      {subHeading && (
        <Text
          variant="title3"
          color="primary"
          className="mb-4 !text-[16px] !leading-[20px] !text-inkwell md:!text-[17px] md:!leading-[21px] lg:mb-5"
        >
          {subHeading}
        </Text>
      )}
      {urlText && url && (
        <div id={mainSec} className="mb-8 flex justify-center lg:justify-start">
          <I13nAnchor
            href={url}
            className="flex"
            dataYlk={{ ...mainDataYlk, slk: urlText }}
          >
            <Button
              variant="primary"
              size="lg"
              className="bg-uh-purple text-[14px] font-bold leading-[20px]"
              tabIndex={-1}
              data-ylk={getDataYlkString({
                ...mainDataYlk,
                slk: urlText,
              })}
            >
              {urlText}
            </Button>
          </I13nAnchor>
        </div>
      )}

      {categoryLinks && categoryLinks.length > 0 && (
        <ul
          id={pillsSec}
          className="flex max-w-[520px] flex-wrap justify-center lg:max-w-none lg:justify-normal"
        >
          {categoryLinks.map((link, index) => (
            <li key={index} className="pb-3 pr-2">
              <I13nAnchor
                href={link?.url}
                className="flex items-center gap-2 rounded-full border border-battleship px-4 py-2 font-bold leading-5 text-batcave duration-[220ms] ease-in-out hover:scale-[1.03]"
                dataYlk={{
                  cpos: index + 1,
                  elm: "link",
                  i13nSec: pillsSec,
                  itc: 0,
                  mpos: pillsPos,
                  sec: pillsSec,
                  slk: link?.title,
                }}
              >
                {link.iconName && (
                  <span className="relative size-4">
                    <DynamicIcons
                      className="absolute"
                      site={site}
                      iconName={link.iconName}
                    />
                  </span>
                )}
                {link?.title}
              </I13nAnchor>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
